import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Menu, Icon } from 'semantic-ui-react'; // Import Icon component
import { useAuth } from '../context/AuthContext';

function Navbar() {
  const { getUser, userIsAuthenticated, userLogout } = useAuth();

  const logout = () => {
    userLogout();
  };

  const enterMenuStyle = () => {
    return userIsAuthenticated() ? { display: 'none' } : { display: 'block' };
  };

  const logoutMenuStyle = () => {
    return userIsAuthenticated() ? { display: 'block' } : { display: 'none' };
  };

  const getUserName = () => {
    const user = getUser();
    return user ? user.data.name : '';
  };

  return (
      <Menu inverted color="purple" stackable size="massive" style={{ borderRadius: 0 }}>
        <Container>
          <Menu.Item header>Mercurycom</Menu.Item>
          {getUser() ?
              <Menu.Item as={Link} exact="true" to="/">
                <Icon name="home" />
                Home
              </Menu.Item>
              : null}
          {getUser() ?
              <Menu.Item as={Link} to="/newAccount">
                <Icon name="user plus" />
                Sincronizar
              </Menu.Item>
              : null}
          <Menu.Menu position="right">
            <Menu.Item as={Link} to="/login" style={enterMenuStyle()}>Login</Menu.Item>
            {getUser() ?
                <Menu.Item as={Link} to="/signup">
                  <Icon name="users" />
                  Usuarios
                </Menu.Item>
              : null}

            <Menu.Item header style={logoutMenuStyle()}>{`Hola ${getUserName()}`}</Menu.Item>
            <Menu.Item as={Link} to="/" style={logoutMenuStyle()} onClick={logout}>
              <Icon name="sign-out" />
              Cerrar Sesión
            </Menu.Item>
          </Menu.Menu>
        </Container>
      </Menu>
  );
}

export default Navbar;
