import axios from 'axios'
import { config } from '../../Constants'
import { parseJwt } from './Helpers'

export const stockyApi = {
  authenticate,
  signup,
  numberOfUsers,
  getItems,
  getUsers,
  deleteUser,
  getUserMe,
  getNewAccountUrl,
  syncAccount,
  updateItem,
  getCategories,
  publishItem,
  updateItems,
  getAttributes,
  getSellers
}

function authenticate(username, password) {
  return instance.post('/auth/authenticate', { username, password }, {
    headers: { 'Content-type': 'application/json' }
  })
}

function signup(user, userAuthenticated) {
  return instance.post('/admin/signup', user, {
    headers: {
      'Content-type': 'application/json',
      'Authorization': bearerAuth(userAuthenticated)
    }
  })
}

function numberOfUsers() {
  return instance.get('/public/numberOfUsers')
}

function getItems(user) {
  const url = '/api/items'
  return instance.get(url, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function getUsers(user, username) {
  const url = username ? `/api/users/${username}` : '/api/users'
  return instance.get(url, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function deleteUser(user, username) {
  return instance.delete(`/api/users/${username}`, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function getUserMe(user) {
  return instance.get('/api/users/me', {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function getNewAccountUrl(accountType, user) {
  return instance.get(`/api/accounts?accountType=${accountType}`, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function syncAccount(code, accountType, user) {
  return instance.get(`/api/accounts/sync?code=${code}&accountType=${accountType}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': bearerAuth(user)
    }
  })
}

function updateItem(item, user) {

  const itemDto = mapToItemDto(item);

  return instance.put(`/api/items/${item.id}`, itemDto, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': bearerAuth(user)
    }
  });
}

function mapToItemDto(item) {
  return {
    id: item.id,
    variation_id: item.variationId,
    marketplace: item.marketplace,
    price: item.price,
    sku: item.sku,
    available_quantity: parseInt(item.availableQuantity, 10),
    seller_id: item.seller ? item.seller.id : 0,
    variations: (item.variations || []).map(variation => ({
      id: variation.id || '',
      price: variation.price || 0,
      quantity: variation.quantity || 0
    }))
  };
}

function getCategories(user, title) {
  const url = `/api/items/categories?title=${title}`

  return instance.get(url , {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': bearerAuth(user)
    }
  })
}

function getAttributes(user, categoryId) {
  const url = `/api/items/categories/${categoryId}/attributes`

  return instance.get(url , {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': bearerAuth(user)
    }
  })
}

function getSellers(user) {
  const url = `/api/sellers`;

  return instance.get(url , {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': bearerAuth(user)
    }
  })
}

function publishItem(user, title, selectedCategory, attributeValues, price, sellers, selectedListing, stock, sku) {
  const attributeEntries = Array.isArray(attributeValues)
    ? attributeValues
    : Object.entries(attributeValues);

  const attributes = [];
  attributeEntries.forEach((attribute, index) => {
    let id, value;
    if (Array.isArray(attribute)) {
      [id, value] = attribute;
    } else {
      id = attribute.id;
      value = attribute.value;
    }
    const attr = {
      id: id,
      value_name: value
    };
    attributes.push(attr);
  });

  const data = {
    title: title,
    category: selectedCategory,
    price: price,
    attributes: attributes,
    sellers: sellers,
    listing_type_id: selectedListing,
    stock: stock,
    sku: sku
  }
  console.log(JSON.stringify(data));
  return instance.post(
    "/api/items/publish",
    JSON.stringify(data),
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': bearerAuth(user)
      }
    }
  );
}

async function updateItems(items, user) {
    const url = '/api/items/bulk';

    const itemsDto = items.map(it => mapToItemDto(it))
    console.log('itemsDto:', itemsDto)
    return instance.put(url, itemsDto, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': bearerAuth(user)
        }
    })
}

// -- Axios

const instance = axios.create({
  baseURL: config.url.API_BASE_URL
})

instance.interceptors.request.use(function (config) {
  // If token is expired, redirect user to login
  if (config.headers.Authorization) {
    const token = config.headers.Authorization.split(' ')[1]
    const data = parseJwt(token)
    if (Date.now() > data.exp * 1000) {
      window.location.href = "/login"
    }
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

// -- Helper functions

function bearerAuth(user) {
  return `Bearer ${user.accessToken}`
}
