import React, { useState } from 'react';
import { FormSelect } from 'semantic-ui-react';

const ListingsSelect = ({ label, setSelectedListing }) => {
    const listings = [
        { site_id: "MLA", id: "gold_pro", name: "Premium" },
        { site_id: "MLA", id: "gold_premium", name: "Oro Premium" },
        { site_id: "MLA", id: "gold_special", name: "Clásica" },
        { site_id: "MLA", id: "gold", name: "Oro" },
        { site_id: "MLA", id: "silver", name: "Plata" },
        { site_id: "MLA", id: "bronze", name: "Bronce" },
        { site_id: "MLA", id: "free", name: "Gratuita" }
    ];

    const [selectedListing, setSelected] = useState(null);

    const handleListingChange = (e, { value }) => {
        setSelectedListing(value);  // Actualiza el valor seleccionado
        setSelected(value); // Actualiza el estado local para reflejar la selección
    };

    return (
        <FormSelect
            fluid
            label={label}
            options={listings.map(listing => ({
                key: listing.id,
                text: listing.name,
                value: listing.id
            }))}
            value={selectedListing}
            onChange={handleListingChange}
        />
    );
};

export default ListingsSelect;
