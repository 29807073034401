import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Table } from 'semantic-ui-react';

const BulkPriceUpdateModal = ({
                                  open,
                                  onClose,
                                  formData,
                                  handleFormChange,
                                  handleFormSubmit,
                                  selectedItems,
                                  items
                              }) => {
    const [localUpdatedPrices, setLocalUpdatedPrices] = useState({});

    useEffect(() => {
        if (!open) {
            setLocalUpdatedPrices({});
        }
    }, [open]);

    const handleCalculate = () => {
        const newPrices = {};
        selectedItems.forEach((itemId) => {
            const item = items.find(i => i.variationId ? i.variationId === itemId : i.id === itemId);
            if (item) {
                let newPrice;
                if (formData.priceType === 'percentage') {
                    newPrice = item.price + (item.price * (formData.increaseAmount / 100));
                } else if (formData.priceType === 'fixed') {
                    newPrice = item.price + parseFloat(formData.increaseAmount);
                }
                newPrices[itemId] = newPrice;
            }
        });
        setLocalUpdatedPrices(newPrices);
    };

    const handleSave = async () => {
        const updatedItems = selectedItems.map(itemId => {
            const item = items.find(i => i.variationId ? i.variationId === itemId : i.id === itemId);
            return {
                ...item,
                price: localUpdatedPrices[itemId] || item.price
            };
        });

        try {
            await handleFormSubmit(updatedItems);  // Pass updated items to the parent
            onClose();
        } catch (error) {
            console.error('Error updating items:', error);
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Modal.Header>Actualizar precios masivamente</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <label>Seleccione aumento por porcentaje (%) o monto fijo ($$):</label>
                        <Form.Group inline>
                            <Form.Radio
                                label='%'
                                name='priceType'
                                value='percentage'
                                checked={formData.priceType === 'percentage'}
                                onChange={handleFormChange}
                            />
                            <Form.Radio
                                label='$$'
                                name='priceType'
                                value='fixed'
                                checked={formData.priceType === 'fixed'}
                                onChange={handleFormChange}
                            />
                        </Form.Group>
                    </Form.Field>
                    <Form.Group>
                        <Form.Input
                            type='number'
                            name='increaseAmount'
                            value={formData.increaseAmount}
                            onChange={handleFormChange}
                            width={14}
                        />
                        <Button onClick={handleCalculate}>Calcular</Button>
                    </Form.Group>
                </Form>
                <Table celled striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Descripción</Table.HeaderCell>
                            <Table.HeaderCell>Variación</Table.HeaderCell>
                            <Table.HeaderCell>Precio</Table.HeaderCell>
                            <Table.HeaderCell>Nuevo precio</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {selectedItems.map((itemId) => {
                            const item = items.find(i => i.variationId ? i.variationId === itemId : i.id === itemId);
                            return item ? (
                                <Table.Row key={itemId}>
                                    <Table.Cell>{item.title}</Table.Cell>
                                    <Table.Cell>{item.sku}</Table.Cell>
                                    <Table.Cell>${item.price}</Table.Cell>
                                    <Table.Cell>${localUpdatedPrices[itemId]?.toFixed(2) || item.price}</Table.Cell>
                                </Table.Row>
                            ) : null;
                        })}
                    </Table.Body>
                </Table>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose}>Cerrar</Button>
                <Button primary onClick={handleSave}>Guardar</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default BulkPriceUpdateModal;
