import React, { useEffect, useState } from 'react';
import { Statistic, Icon, Grid, Container, Segment, Dimmer, Loader, Table, Button, Label, Form, Input, Dropdown  } from 'semantic-ui-react';
import { stockyApi } from '../misc/StockyApi';
import { useAuth } from "../context/AuthContext";
import EditItemModal from '../items/EditItemModal';
import BulkPriceUpdateModal from '../items/BulkPriceUpdateModal';
import ViewItemModal from '../items/ViewItemModal';
import SemanticUiReactSnackbar from '../misc/SemanticUiReactSnackbar';
import ItemsTable from '../items/Items';
import images from '../../images';
import {useNavigate} from "react-router-dom";

function Home() {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [bulkPriceUpdateModalOpen, setBulkPriceUpdateModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);  // State to control view modal
  const [editFormData, setEditFormData] = useState({ id: '', title: '', sku: '', availableQuantity: '', price: '' });
  const [bulkPriceUpdateFormData, setBulkPriceUpdateFormData] = useState({ priceType: 'percentage', increaseAmount: '' });
  const [updatedPrices, setUpdatedPrices] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false); // State to control "Select All"
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchColumn, setSearchColumn] = useState('title');
  const [showMessage, setShowMessage] = useState(false); // For controlling snackbar visibility
  const [snackbarMessage, setSnackbarMessage] = useState(''); // To hold the message text
  const [snackbarVariant, setSnackbarVariant] = useState('success'); // To hold the message type (success, error, etc.)
  const navigate = useNavigate();

  const Auth = useAuth();
  const user = Auth.getUser();

  const fetchItems = async () => {
    try {
      const response = await stockyApi.getItems(user);
      const items = response.data;
      setItems(items);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  useEffect(() => {
    if (!isLoading && items.length === 0) {
      navigate('/newAccount');
    }
  }, [isLoading, items, navigate]);

  useEffect(() => {
    fetchItems().finally(() => setIsLoading(false));
  }, []);

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(filteredItems.map(item => item.variationId ? item.variationId : item.id));
    }
    setSelectAll(!selectAll);
  };

  const handleEditClick = (item) => {
    setSelectedItem(item);
    setEditFormData(item);
    setEditModalOpen(true);
  };

  const handleViewClick = (item) => {
    setSelectedItem(item);
    console.log('Selected item2:', selectedItem);
    setViewModalOpen(true);
  };

  const handleBulkPriceUpdateModalOpen = () => {
    setBulkPriceUpdateModalOpen(true);
  };

  const handleBulkPriceUpdateModalClose = () => {
    setBulkPriceUpdateModalOpen(false);
  };

  const handleEditFormChange = (e, { name, value }) => {
    setEditFormData({ ...editFormData, [name]: value });
  };

  const handleBulkPriceUpdateFormChange = (e, { name, value }) => {
    setBulkPriceUpdateFormData({ ...bulkPriceUpdateFormData, [name]: value });
  };

  const handleEditFormSubmit = async () => {
    try {
      setIsLoading(true);
      const updatedItem = await stockyApi.updateItem(editFormData, user);
      const updatedItemsArray = Array.isArray(updatedItem) ? updatedItem : [updatedItem];
      for (const itemToUpd of updatedItemsArray) {
        setItems(prevItems => prevItems.map(item => item.id === itemToUpd.id ? itemToUpd : item));
      }
      await fetchItems();

      setEditModalOpen(false);
      setIsLoading(false);

      setSnackbarVariant('success');
      setSnackbarMessage('Item updated successfully!');
      setShowMessage(true);
    } catch (error) {
      console.error('Error updating item:', error);
      setSnackbarVariant('error');
      setSnackbarMessage('Error updating item.');
      setShowMessage(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBulkPriceUpdateFormSubmit = async (updatedPrices) => {
    try {
      setIsLoading(true);
      const updatedItems = await stockyApi.updateItems(updatedPrices, user);
      const updatedItemsArray = Array.isArray(updatedItems) ? updatedItems : [updatedItems];
      for (const itemToUpd of updatedItemsArray) {
        setItems(prevItems => prevItems.map(item => item.id === itemToUpd.id ? itemToUpd : item));
      }
      await fetchItems();
      setBulkPriceUpdateModalOpen(false);
      setIsLoading(false);

      setSnackbarVariant('success');
      setSnackbarMessage('Item updated successfully!');
      setShowMessage(true);
    } catch (error) {
      console.error('Error updating items:', error);
      setSnackbarVariant('error');
      setSnackbarMessage('Error updating item.');
      setShowMessage(true);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Segment basic style={{ marginTop: window.innerHeight / 2 }}>
        <Dimmer active inverted>
          <Loader inverted size='huge'>Loading</Loader>
        </Dimmer>
      </Segment>
    );
  }

  const isSelectedItem = selectedItems.length !== 0;

  const handleSort = (clickedColumn) => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      setItems([...items].sort((a, b) => (a[clickedColumn] > b[clickedColumn] ? 1 : -1)));
      setDirection('ascending');
    } else {
      setItems([...items].reverse());
      setDirection(direction === 'ascending' ? 'descending' : 'ascending');
    }
  };

  const filteredItems = items.filter(item => {
    const searchTermLower = searchTerm.toLowerCase();

    // Asegúrate de que las propiedades existan antes de aplicar el filtro
    const titleMatch = item.title?.toLowerCase().includes(searchTermLower);
    const variationNameMatch = item.variationName?.toLowerCase().includes(searchTermLower);
    const skuMatch = item.sku?.toLowerCase().includes(searchTermLower);

    return titleMatch || variationNameMatch || skuMatch;
  });


  return (
    <Container fluid>
      {user ? (
        <Segment>
          <Grid stackable columns={4}>
            <Grid.Row>
              <Grid.Column textAlign="center" style={{ marginLeft: '-1.5em' }}>
                <Button
                  icon
                  labelPosition="left"
                  onClick={handleBulkPriceUpdateModalOpen}
                  disabled={!isSelectedItem}
                  color="purple"
                >
                  <Icon name="sync alternate" />
                  Precios
                </Button>
              </Grid.Column>

              {/* Campo de búsqueda */}
              <Grid.Column textAlign="right" floated="right">
                <Input
                  icon="search"
                  placeholder="Buscar en Título, Variación o SKU..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <ItemsTable
            column={column}
            direction={direction}
            handleSort={handleSort}
            handleSelectAll={handleSelectAll}
            selectAll={selectAll}
            TableContentComponent={TableContentComponent}
            filteredItems={filteredItems}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            handleEditClick={handleEditClick}
            handleViewClick={handleViewClick}
          />
        </Segment>
      ) : null}

      <EditItemModal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        item={editFormData}
        onChange={handleEditFormChange}
        onSubmit={handleEditFormSubmit}
      />

      <BulkPriceUpdateModal
        open={bulkPriceUpdateModalOpen}
        onClose={handleBulkPriceUpdateModalClose}
        formData={bulkPriceUpdateFormData}
        handleFormChange={handleBulkPriceUpdateFormChange}
        handleFormSubmit={handleBulkPriceUpdateFormSubmit}
        selectedItems={selectedItems}
        items={items}
        updatedPrices={updatedPrices}
      />

      <ViewItemModal
        open={viewModalOpen}
        onClose={() => setViewModalOpen(false)}
        item={selectedItem}
      />

      {/* Snackbar Component */}
      <SemanticUiReactSnackbar
        variant={snackbarVariant}
        visible={showMessage}
        setVisible={setShowMessage}
      >
        {snackbarMessage}
      </SemanticUiReactSnackbar>
    </Container>
  );
}

export default Home;

const TableContentComponent = ({
  args:{
      filteredItems,
      selectedItems,
      setSelectedItems,
      handleEditClick,
      handleViewClick
    }
  }) => {
    const handleCheckboxChange = (itemId) => {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.includes(itemId)
          ? prevSelectedItems.filter(id => id !== itemId)
          : [...prevSelectedItems, itemId]
      );
    };
  return (
    <Table.Body>
              {filteredItems.map((item, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>
                      <Form.Checkbox
                          checked={selectedItems.includes(item.variationId ? item.variationId : item.id)}
                          onChange={() => handleCheckboxChange(item.variationId ? item.variationId : item.id)}
                          style={{justifyContent: 'start', alignItems: 'center', padding: '5px'}}
                      />
                    </Table.Cell>

                    <Table.Cell style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px'}}>
                      <a href={item.permalink} target="_blank" rel="noopener noreferrer"
                         style={{color: '#4183c4', fontWeight: 'bold'}}>
                        {item.title}
                      </a>
                    </Table.Cell>

                    <Table.Cell style={{textAlign: 'center', verticalAlign: 'middle', padding: '10px'}}>
                      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                        {item.variationName ? (
                            item.variationName
                        ) : (
                            <Icon name="ban"/>
                        )}
                      </div>
                    </Table.Cell>

                    <Table.Cell style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px'}}>
                      {item.sku}
                    </Table.Cell>

                    <Table.Cell style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px'}}>
                      {item.availableQuantity}
                    </Table.Cell>

                    <Table.Cell style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px'}}>
                      ${item.price}
                    </Table.Cell>

                    <Table.Cell style={{textAlign: 'center', verticalAlign: 'middle', padding: '10px'}}>
                      <Label size="small" color={item.status === 'active' ? 'green' : 'yellow'}>
                        {item.status}
                      </Label>
                    </Table.Cell>

                    <Table.Cell style={{textAlign: 'left', verticalAlign: 'middle', padding: '10px'}}>
                      <a href={item.seller.permalink} target="_blank" rel="noopener noreferrer"
                         style={{color: '#4183c4', fontWeight: 'bold'}}>
                        {item.seller.nickname.toLowerCase()}
                      </a>
                    </Table.Cell>

                    <Table.Cell style={{ textAlign: 'center', verticalAlign: 'middle', padding: '10px' }}>
                      <img
                          src={item.marketplace === 'mercado_libre' ? images.meliIcon : images.tnIcon}
                          alt="icon"
                          style={{ width: '30px', height: '30px' }}
                      />
                    </Table.Cell>


                    <Table.Cell style={{textAlign: 'center', verticalAlign: 'middle', padding: '10px'}}>
                      <Button circular color='purple' size='mini' icon='edit' onClick={() => handleEditClick(item)}/>
                      <Button circular color='olive' size='mini' icon='eye' onClick={() => handleViewClick(item)}/>
                    </Table.Cell>
                  </Table.Row>
              ))}
    </Table.Body>
  )
};
