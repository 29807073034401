import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { stockyApi } from '../misc/StockyApi';
import { useAuth } from "../context/AuthContext";
import AttributeInputFactory from './attributes/AttributeInputFactory';
import SellersSelect from '../sellers/SellersSelect';
import PriceInput from '../price/PriceInput';
import ListingsSelect from './Listings';
import {
  Button,
  Modal,
  FormSelect,
  FormInput,
  FormGroup,
  Form,
  Segment,
  Dimmer,
  Loader,
  Accordion,
  Icon
} from 'semantic-ui-react';

const PublishItem = () => {
  const Auth = useAuth();
  const user = Auth.getUser();

  const [title, setTitle] = useState('');
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [showCategoryForm, setShowCategoryForm] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [showAttributes, setShowAttributes] = useState(false);
  const [carPhotos, setCarPhotos] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1); // State for accordion control
  const [attributeValues, setAttributeValues] = useState({});
  const [price, setPrice] = useState('');
  const [selectedSellers, setSelectedSellers] = useState([]);
  const [selectedListing, setSelectedListing] = useState('');
  const [stock, setStock] = useState('');
  const [sku, setSku] = useState('');

  const handleAttributeChange = (attributeId, value) => {
    setAttributeValues(prevValues => ({
        ...prevValues,
        [attributeId]: value,
    }));
  };

  const handlePriceChange = (formattedPrice) => {
    setPrice(formattedPrice);
  }

  const handleFileChange = (e) => {
    setCarPhotos(e.target.files);
  };

  const getCategories = async () => {
    if (title.length > 2) {
      setIsLoading(true);
      try {
        const categoriesResponse = await stockyApi.getCategories(user, title);
        const categories = categoriesResponse.data;
        setCategoryOptions(categories);
        setShowCategoryForm(true);
        setShowAttributes(false);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    if (title.length > 2) {
      setTypingTimeout(setTimeout(() => {
        getCategories();
      }, 600));
    }

    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [title]);

  const handleCategoryChange = (e, { value }) => {
    setSelectedCategory(value);
    setShowAttributes(false);
    getAttributes(value);
  };

  const getAttributes = async (categoryId) => {
    if (categoryId) {
      setIsLoading(true);
      try {
        const attributesCategory = await stockyApi.getAttributes(user, categoryId);
        const attributes = attributesCategory.data;
        setAttributes(attributes);
        setShowAttributes(true);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  if (isLoading) {
    return (
        <Segment basic style={{ marginTop: window.innerHeight / 2 }}>
          <Dimmer active inverted>
            <Loader inverted size='huge'>Cargando</Loader>
          </Dimmer>
        </Segment>
    );
  }

  const handleListingChange = (selectedListingsData) => {
    setSelectedListing(selectedListingsData)
  }

  const publishItem = async () => {
    //setIsLoading(true);
    const response = await stockyApi.publishItem(
      user, title, selectedCategory, attributeValues, price, selectedSellers, selectedListing, stock, sku)
    .then(response => {
      const result = response.json();
      // TODO: Add snackbar
      console.log('Item published:', result);
      setIsLoading(false);
    })
    .catch(error => {
      setIsLoading(false)
      // TODO: Add snackbar
      throw new Error('Network response was not ok', error);
    });
  };

  const handleNumericKeyDown = (e) => {
    const allowedKeys = [
        'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight',
        'Tab', 'Enter', 'Escape', '.'
    ];

    if (
        !/[0-9]/.test(e.key) &&
        !allowedKeys.includes(e.key)
    ) {
        e.preventDefault();
    }
  };

  const handleStockChange = (e, {value}) => {
    setStock(value);
  }

  const handleSkuChange = (e, {value}) => {
    setSku(value);
  }

  const isConditionalRequired = (attribute) => {
    return attribute.tags && attribute.tags.conditional_required;
  }

  const isRequired = (attribute) => {
    return attribute.tags
      && (attribute.tags.catalog_required
        || attribute.tags.required
        || (isConditionalRequired(attribute)));
  }

  return (
      <div className="container mt-5">
        <h1>Publicar Producto en MercadoLibre</h1>
        <Form>
          <SellersSelect user={user} label={"Seleccione las cuentas donde publicará el item"} setSelectedSellers={setSelectedSellers} />
          <FormGroup widths='equal'>
            <FormInput
                fluid
                label='Título'
                name="title"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                placeholder='Título'
            />
          </FormGroup>

          {showCategoryForm && (
              <FormGroup widths='equal'>
                <FormSelect
                    fluid
                    label='Categorías'
                    name="categories"
                    id="categories"
                    options={[
                      { text: 'Seleccionar', value: '' },
                      ...categoryOptions.map(category => ({
                        text: category.domain_name,
                        value: category.category_id
                      }))
                    ]}
                    value={selectedCategory || ''}
                    onChange={handleCategoryChange}
                    required
                />
              </FormGroup>
          )}

          {showAttributes && (
              <>
                {attributes.filter(attribute => attribute.tags?.required).map(attribute => (
                    <AttributeInputFactory
                      key={attribute.id}
                      attribute={attribute}
                      value={attributeValues[attribute.id] || ''}
                      onChange={handleAttributeChange} />
                ))}

                <FormInput
                    label={"Stock"}
                    name={"Stock"}
                    id={"stock"}
                    value={stock}
                    fluid
                    placeholder={'Ingrese stock'}
                    required={true}
                    onChange={handleStockChange}
                    onKeyDown={handleNumericKeyDown}
                />

                <FormInput
                    label={"SKU"}
                    name={"SKU"}
                    id={"sku"}
                    value={sku}
                    onChange={handleSkuChange}
                    fluid
                    placeholder={'Ingrese SKU'}
                />

                <PriceInput price={price} handlePriceChange={handlePriceChange}/>

                <ListingsSelect label={"Seleccione tipo de publicación"} setSelectedListing={handleListingChange} />

                <Accordion>
                  <Accordion.Title
                      active={activeIndex === 0}
                      index={0}
                      onClick={handleAccordionClick}
                  >
                    <Icon name='dropdown' />
                    Opcionales
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 0}>
                    {attributes.filter(attribute => !isRequired(attribute)).map(attribute => (
                        <AttributeInputFactory
                          key={attribute.id}
                          attribute={attribute}
                          value={attributeValues[attribute.id] || ''}
                          onChange={handleAttributeChange} />
                    ))}
                  </Accordion.Content>
                </Accordion>
              </>
          )}
          <Button onClick={publishItem} positive>Publicar ítem</Button>
        </Form>

        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <Modal.Header>Publicación Exitosa</Modal.Header>
          <Modal.Content>
            <p>El ítem se ha publicado correctamente.</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setModalOpen(false)}>Cerrar</Button>
          </Modal.Actions>
        </Modal>
      </div>
  );
};

export default PublishItem;
