import React, { useState } from 'react';
import { FormInput } from 'semantic-ui-react';

const PriceInput = ({price, handlePriceChange}) => {

    const onPriceChange = (e) => {
        const inputValue = e.target.value.replace(/[^0-9]/g, ''); // Remueve todo excepto números
        if (inputValue) {
            handlePriceChange(formatPrice(inputValue));
        } else {
            handlePriceChange('');
        }
    };

    const handleKeyDown = (e) => {
        const allowedKeys = [
            'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight',
            'Tab', 'Enter', 'Escape', '.'
        ];

        if (
            !/[0-9]/.test(e.key) &&
            !allowedKeys.includes(e.key)
        ) {
            e.preventDefault();
        }
    };

    const formatPrice = (value) => {
         // Separar el valor en parte entera y parte decimal
         let integerPart = value.slice(0, -2) || '0'; // Parte entera
         let decimalPart = value.slice(-2).padStart(2, '0'); // Últimos dos dígitos como decimales + relleno de 0s

         // Formatear parte entera con separadores de miles usando el punto
         integerPart = Number(integerPart).toLocaleString('es-ES', {
             minimumFractionDigits: 0
         });

         return `$ ${integerPart},${decimalPart}`;
    };

    return (
        <FormInput
            label="Precio"
            name="price"
            id="price"
            fluid
            placeholder='Ingrese precio'
            value={price}
            onChange={onPriceChange}
            required={true}
            onKeyDown={handleKeyDown}
        />
    );
};

export default PriceInput;
