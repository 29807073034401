import React, { useEffect, useState } from 'react';
import { Icon, Message } from 'semantic-ui-react';
import css from "../misc/snackbar.css";

const SemanticUiReactSnackbar = ({ variant, children, visible, setVisible }) => {
    useEffect(() => {
        if (visible) {
            const timer = setTimeout(() => setVisible(false), 5000);
            return () => clearTimeout(timer);
        }
    }, [visible, children, setVisible]);

    return visible ? (
        <Message
            info={variant === "info"}
            error={variant === "error"}
            success={variant === "success"}
            className={css.message}
            style={{ position: 'absolute', top: '95%', right: '10px', fontSize: '12px', padding: '5px', width: '200px' }}
        >
            <Icon
                name={
                    variant === "success"
                        ? "check circle"
                        : variant === "error"
                            ? "times circle"
                            : variant === "info"
                                ? "info circle"
                                : null
                }
            />
            <b>
                {variant === "success"
                    ? "Ok! "
                    : variant === "error"
                        ? "Error! "
                        : variant === "info"
                            ? "Warning! "
                            : null}
            </b>
            <span>{children}</span>
            <Icon
                name="times"
                style={{ float: "right", cursor: "pointer" }}
                onClick={() => setVisible(false)}
            />
        </Message>
    ) : null;
};

export default SemanticUiReactSnackbar;
