import React from 'react';
import { Button, Form, Modal, Label, Divider, LabelDetail } from 'semantic-ui-react';

function EditItemModal({ open, onClose, item, onChange, onSubmit }) {
    const handleSubmit = async () => {
        try {
            await onSubmit(item); // Pass the modified item to the onSubmit handler
            onClose(); // Close modal on successful submission
        } catch (error) {
            console.error('Failed to update item:', error);
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Modal.Header>Edit Item</Modal.Header>
            <Modal.Content>
                <Label size='large'>
                    {item.title}
                    <LabelDetail>{item.sku}</LabelDetail>
                </Label>
                <Divider />
                <Form>
                    <Form.Input
                        label='Cantidad Disponible'
                        name='availableQuantity'
                        value={item.availableQuantity}
                        onChange={onChange}
                    />
                    <Form.Input
                        label='Precio'
                        name='price'
                        value={item.price}
                        onChange={onChange}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button primary onClick={handleSubmit}>Guardar</Button>
            </Modal.Actions>
        </Modal>
    );
}

export default EditItemModal;
