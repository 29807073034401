import { useEffect } from 'react';

const AddTrackingScript = () => {
  useEffect(() => {
    // Load Amplitude Analytics script
    const analyticsScript = document.createElement('script');
    analyticsScript.src = 'https://cdn.amplitude.com/libs/analytics-browser-2.11.1-min.js.gz';
    analyticsScript.async = true;
    document.head.appendChild(analyticsScript);

    // Load Amplitude Session Replay plugin script
    const sessionReplayScript = document.createElement('script');
    sessionReplayScript.src = 'https://cdn.amplitude.com/libs/plugin-session-replay-browser-1.6.22-min.js.gz';
    sessionReplayScript.async = true;
    document.head.appendChild(sessionReplayScript);

    // Wait until the scripts are fully loaded
    analyticsScript.onload = () => {
      sessionReplayScript.onload = () => {
        if (window.amplitude && window.sessionReplay) {
          // Initialize Amplitude with the session replay plugin
          window.amplitude.add(window.sessionReplay.plugin({ sampleRate: 1 }));
          window.amplitude.init('9b1ca5882e5f60b6b7db0520cbb8bece', {
            autocapture: { elementInteractions: true }
          });
        } else {
          console.error('Amplitude or session replay plugin could not be loaded');
        }
      };
    };

    analyticsScript.onerror = () => {
      console.error('Failed to load Amplitude analytics script');
    };
    sessionReplayScript.onerror = () => {
      console.error('Failed to load Amplitude session replay script');
    };
  }, []);

  return null; // This component doesn't render anything
};

export default AddTrackingScript;
