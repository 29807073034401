import React, { useState } from 'react';
import { NavLink, Navigate } from 'react-router-dom';
import { Button, Form, Grid, Segment, Message } from 'semantic-ui-react';
import { useAuth } from '../context/AuthContext';
import { stockyApi } from '../misc/StockyApi';
import { parseJwt, handleLogError } from '../misc/Helpers';

function Signup() {
  const Auth = useAuth();
  const isLoggedIn = Auth.userIsAuthenticated();

  const userAuthenticated = Auth.getUser();
  const isAdmin = Auth.isAdmin();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // New state for confirm password
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [redirectToHome, setRedirectToHome] = useState(false);

  const handleInputChange = (e, { name, value }) => {
    if (name === 'username') {
      setUsername(value);
    } else if (name === 'password') {
      setPassword(value);
    } else if (name === 'confirmPassword') { // Handle confirm password
      setConfirmPassword(value);
    } else if (name === 'name') {
      setName(value);
    } else if (name === 'surname') {
      setSurname(value);
    } else if (name === 'email') {
      setEmail(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!(username && password && confirmPassword && name && surname && email)) {
      setIsError(true);
      setErrorMessage('Please, inform all fields!');
      return;
    }

    if (password !== confirmPassword) {
      setIsError(true);
      setErrorMessage('Passwords do not match!');
      return;
    }

    const user = { username, password, name, surname, email };

    try {
      const response = await stockyApi.signup(user, userAuthenticated);
      setRedirectToHome(true);
      setIsError(false);
      setErrorMessage('');
    } catch (error) {
      handleLogError(error);
      if (error.response && error.response.data) {
        const errorData = error.response.data;
        let errorMessage = 'Invalid fields';
        if (errorData.status === 409) {
          errorMessage = errorData.message;
        } else if (errorData.status === 400) {
          errorMessage = errorData.errors[0].defaultMessage;
        }
        setIsError(true);
        setErrorMessage(errorMessage);
      }
    }
  };

  if (!isLoggedIn  || !isAdmin || redirectToHome) {
    return <Navigate to='/' />;
  }

  return (
      <Grid textAlign='center'>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Form size='large' onSubmit={handleSubmit}>
            <Segment>
              <Form.Input
                  fluid
                  autoFocus
                  name='username'
                  icon='user'
                  iconPosition='left'
                  placeholder='Username'
                  value={username}
                  onChange={handleInputChange}
              />
              <Form.Input
                  fluid
                  name='password'
                  icon='lock'
                  iconPosition='left'
                  placeholder='Password'
                  type='password'
                  value={password}
                  onChange={handleInputChange}
              />
              <Form.Input
                  fluid
                  name='confirmPassword'
                  icon='lock'
                  iconPosition='left'
                  placeholder='Confirm Password'
                  type='password'
                  value={confirmPassword}
                  onChange={handleInputChange}
              />
              <Form.Input
                  fluid
                  name='name'
                  icon='address card'
                  iconPosition='left'
                  placeholder='Name'
                  value={name}
                  onChange={handleInputChange}
              />
              <Form.Input
                  fluid
                  name='surname'
                  icon='address book'
                  iconPosition='left'
                  placeholder='Surname'
                  value={surname}
                  onChange={handleInputChange}
              />
              <Form.Input
                  fluid
                  name='email'
                  icon='at'
                  iconPosition='left'
                  placeholder='Email'
                  value={email}
                  onChange={handleInputChange}
              />
              <Button color='purple' fluid size='large'>Register</Button>
            </Segment>
          </Form>
          {isError && (
          <Message negative style={{ position: 'absolute', top: '95%', right: '10px', fontSize: '12px', padding: '5px', width: '200px' }}>
            The username or password provided are incorrect!
          </Message>
          )}
        </Grid.Column>
      </Grid>
  );
}

export default Signup;
