import React from 'react';
import { Modal, Button, Table } from 'semantic-ui-react';

const ViewItemModal = ({ open, onClose, item }) => {
    // Provide default values for item properties
    const defaultItem = {
        id: '',
        title: '',
        condition: 'N/A',
        permalink: '',
        categoryId: 'N/A',
        price: 'N/A',
        soldQuantity: 'N/A',
        availableQuantity: 'N/A',
        status: 'N/A',
        sku: 'N/A',
        brand: 'N/A',
        marketplace: 'N/A',
        seller: { nickname: 'N/A' }
    };

    const currentItem = item || defaultItem;

    return (
        <Modal open={open} onClose={onClose}>
            <Modal.Header>View Item</Modal.Header>
            <Modal.Content>
                <Table celled>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell><strong>ID</strong></Table.Cell>
                            <Table.Cell>{currentItem.id}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><strong>Título</strong></Table.Cell>
                            <Table.Cell>{currentItem.title}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><strong>Condición</strong></Table.Cell>
                            <Table.Cell>{currentItem.condition}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><strong>Link</strong></Table.Cell>
                            <Table.Cell>
                                <a href={currentItem.permalink} target="_blank" rel="noopener noreferrer">
                                    {currentItem.permalink}
                                </a>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><strong>Categoría</strong></Table.Cell>
                            <Table.Cell>{currentItem.categoryId}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><strong>Precio</strong></Table.Cell>
                            <Table.Cell>${currentItem.price}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><strong>Cantidad Vendida</strong></Table.Cell>
                            <Table.Cell>{currentItem.soldQuantity}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><strong>Cantidad Disponible</strong></Table.Cell>
                            <Table.Cell>{currentItem.availableQuantity}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><strong>Estado</strong></Table.Cell>
                            <Table.Cell>{currentItem.status}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><strong>SKU</strong></Table.Cell>
                            <Table.Cell>{currentItem.sku}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><strong>Marca</strong></Table.Cell>
                            <Table.Cell>{currentItem.brand}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><strong>Marketplace</strong></Table.Cell>
                            <Table.Cell>{currentItem.marketplace}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><strong>Vendedor</strong></Table.Cell>
                            <Table.Cell>{currentItem.seller.nickname}</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose}>Cerrar</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default ViewItemModal;
