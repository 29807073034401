import React, { useState, useEffect } from 'react';
import { FormSelect } from 'semantic-ui-react';
import { stockyApi } from '../misc/StockyApi';

const SellersSelect = ({ user, label, setSelectedSellers }) => {
    const [sellers, setSellers] = useState([]);
    const [selectedSellerIds, setSelectedSellerIds] = useState([]);

    useEffect(() => {
        const fetchSellers = async () => {
            try {
                const response = await stockyApi.getSellers(user);
                setSellers(response.data);
            } catch (error) {
                console.error('Error fetching sellers:', error);
            }
        };

        fetchSellers();
    }, [user]);

    const handleSellerChange = (e, { value }) => {
        setSelectedSellerIds(value);
        // value contiene los IDs seleccionados
        const selectedSellersData = sellers.filter(seller => value.includes(seller.id));

        // Aquí puedes acceder a los IDs, nicknames y marketplaces de los sellers seleccionados
        console.log(selectedSellersData);

        // Enviar los datos seleccionados hacia el estado superior
        setSelectedSellers(selectedSellersData);
    };

    return (
        <FormSelect
            fluid
            multiple={true}
            label={label}
            options={sellers.map(seller => ({
                key: seller.id,
                text: seller.nickname,
                value: seller.id
            }))}
            value={selectedSellerIds}
            onChange={handleSellerChange}
        />
    );
};

export default SellersSelect;
