import React, { useState } from 'react';
import {
    FormSelect,
    FormInput,
    FormGroup,
    Checkbox
} from 'semantic-ui-react';

const AttributeInputFactory = ({ attribute, value, onChange }) => {
    const [noCode, setNoCode] = useState(false); // Estado para controlar el fallback

    const handleChange = (e, { value }) => {
        onChange(attribute.id, value);
    };

    const handleCheckboxChange = (e, { checked }) => {
        setNoCode(checked);
        if (checked) {
            // Si selecciona "No tengo el código", resetea el input principal
            onChange(attribute.id, '');
        }
    };

    // Filtramos atributos que no deben mostrarse
    const shouldHide = attribute.tags && (
        (attribute.tags.hidden && !attribute.tags.conditional_required) || attribute.tags.read_only);
        console.log(attribute.name + " shouldhide " + shouldHide);
    if (shouldHide) {
        return null;
    }

    // Determinamos si el campo debe ser requerido
    const isConditionalRequired = attribute.tags && attribute.tags.conditional_required;
    const isRequired = attribute.tags
        && (attribute.tags.catalog_required
            || attribute.tags.required
            || (isConditionalRequired && !noCode));

    return (
        <FormGroup widths='equal'>
            {attribute.value_type === 'string' && (
                <FormInput
                    label={attribute.name}
                    name={attribute.id}
                    id={attribute.id}
                    value={value}
                    fluid
                    maxLength={attribute.value_max_length}
                    placeholder={attribute.hint || ''}
                    required={isRequired}
                    onChange={handleChange}
                />
            )}
            {attribute.value_type === 'number_unit' && (
                <FormInput
                    label={attribute.name}
                    name={attribute.id}
                    id={attribute.id}
                    value={value}
                    fluid
                    maxLength={attribute.value_max_length}
                    placeholder={attribute.hint || ''}
                    onChange={handleChange}
                    required={isRequired}
                    onKeyDown={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                />
            )}
            {attribute.value_type === 'boolean' && (
                <FormSelect
                    fluid
                    label={attribute.name}
                    name={attribute.id}
                    id={attribute.id}
                    value={value}
                    options={[
                        { key: 'Si', text: 'Si', value: 'si' },
                        { key: 'No', text: 'No', value: 'no' },
                    ]}
                    required={isRequired}
                    onChange={handleChange}
                />
            )}
            {attribute.value_type === 'list' && (
                <FormSelect
                    fluid
                    label={attribute.name}
                    name={attribute.id}
                    id={attribute.id}
                    options={attribute.values.map(value => ({
                        text: value.name,
                        value: value.id,
                        key: value.id
                    }))}
                    value={value}
                    required={isRequired}
                    onChange={handleChange}
                />
            )}
            {attribute.value_type !== 'string' &&
                attribute.value_type !== 'number_unit' &&
                attribute.value_type !== 'boolean' &&
                attribute.value_type !== 'list' && (
                    <FormInput
                        label={attribute.name}
                        name={attribute.id}
                        id={attribute.id}
                        value={value}
                        fluid
                        maxLength={attribute.value_max_length}
                        placeholder={attribute.hint || ''}
                        required={isRequired}
                        onChange={handleChange}
                    />
                )}

            {/* Checkbox "No tengo el código" */}
            {isConditionalRequired && (
                <Checkbox
                    fluid
                    label="No tengo el código"
                    checked={noCode}
                    onChange={handleCheckboxChange}
                />
            )}

            {/* Input fallback */}
            {noCode && (
                <AttributeInputFactory
                key={attribute.id}
                attribute={attribute}
                value={value}
                onChange={handleChange} />
            )}
        </FormGroup>
    );
};

export default AttributeInputFactory;
